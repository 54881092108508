document.addEventListener('DOMContentLoaded', () => {
  document.getElementById('togglePassword').addEventListener('click', (e) => {

    const password = document.getElementById('password');
    const eye = document.querySelector('.fa-eye');
    const eyeSlash = document.querySelector('.fa-eye-slash');

    password.setAttribute('type', password.getAttribute('type') === 'password' ? 'text' : 'password');
    eyeSlash.classList.toggle('is-hidden');
    eye.classList.toggle('is-hidden');
  });
});